import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { LayoutService } from '@vex/services/layout.service';
import { filter, map, startWith } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '@vex/utils/check-router-childs-data';
import { ConfigService } from '@vex/services/config.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SidebarComponent } from '@vex/components/sidebar/sidebar.component';
import { SignalRService } from '@vex/services/signal-r.service';
import { AuthService } from '@vex/http-service/auth.service';
import { NotificationLocalService } from '@vex/services/local-notification.service';
import { LocalStorageService } from '@vex/services/local-storage.service';
import { HelperService } from '@vex/services/helper.service';
@UntilDestroy()
@Component({
  selector: 'vex-custom-layout',
  templateUrl: './custom-layout.component.html',
  styleUrls: ['./custom-layout.component.scss']
})

export class CustomLayoutComponent implements OnInit {
  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
  isDesktop$ = this.layoutService.isDesktop$;
  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  );

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;

  constructor(
    public layoutService: LayoutService,
    public signal: SignalRService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    private localNotification: NotificationLocalService,
    private localStorageService: LocalStorageService,
    private helperService: HelperService
  ) { 
    this.authService.checkUserInactivity();
    this.signal.initialize();
    this.localNotification.FetchNotifications();
  }

  ngOnInit() {
    this.layoutService.configpanelOpen$.pipe(
      untilDestroyed(this)
    ).subscribe(open => open ? this.configpanel.open() : this.configpanel.close());
    this.getWorkerUpdates();
  }

  getWorkerUpdates() {
    this.signal.getDataBySignalR('WorkerNodeUpdate').subscribe((message) => {
      message['IsRead'] = false;
      this.layoutService.workerMessages.push(message);
      this.layoutService.messageCount.next(this.layoutService.workerMessages.length);
    });
  }

  @HostListener('window:mousemove') refreshUserState() {
    this.setIdleTime();
  }

  @HostListener('window:keypress') refreshState() {
    this.setIdleTime();
  }

  setIdleTime() {
    if(this.authService.isLoggedIn()) {
      if(!localStorage.getItem('sessionConfirmation')) {
        localStorage.setItem('IdleTime', new Date().getTime().toString());
        clearInterval(this.authService.userActivity);
        this.authService.checkUserInactivity();
      }
    }
  }

  @HostListener('window:beforeunload') onPageClose() {
    if(this.localStorageService.isStoretoSession) {
      this.localStorageService.setAllkeysToSession();
    }
    this.authService.clearAllTimer();
    if(this.helperService.changeRequestData) {
      sessionStorage.setItem('ChangeProcessData', JSON.stringify(this.helperService.changeRequestData));
    }
    if(!this.authService.isLoginPage) {
      sessionStorage.setItem('ActiveTab', 'true');
      let activeTab = Number(localStorage.getItem('ActiveTab'));
      localStorage.setItem('ActiveTab', (activeTab - 1).toString());
    }
  }
}
