import { Component, OnInit } from '@angular/core';
import icLayers from '@iconify/icons-ic/twotone-layers';
import icAssigment from '@iconify/icons-ic/twotone-assignment';
import { Icon } from '@visurel/iconify-angular';
import icContactSupport from '@iconify/icons-ic/twotone-contact-support';
import icContacts from '@iconify/icons-ic/twotone-contacts';
import icAssessment from '@iconify/icons-ic/twotone-assessment';
import icBook from '@iconify/icons-ic/twotone-book';
import { PopoverRef } from '../popover/popover-ref';
import { NavigationService } from '@vex/services/navigation.service';
import { ModuleService } from '@vex/http-service/module.service';
import { NavigationLink } from '@vex/interfaces/navigation-item.interface';

export interface MegaMenuFeature {
  icon: Icon;
  label: string;
  route: string;
}

export interface MegaMenuPage {
  label: string;
  route: string;
}

@Component({
  selector: 'vex-mega-menu',
  templateUrl: './mega-menu.component.html'
})
export class MegaMenuComponent implements OnInit {
  navigationItems = [];
  dashboardIcon = icLayers;
  features: MegaMenuFeature[] = [
    {
      icon: icLayers,
      label: 'Dashboard',
      route: '/'
    },
    {
      icon: icAssigment,
      label: 'AIO-Table',
      route: '/apps/aio-table'
    },
    {
      icon: icContactSupport,
      label: 'Help Center',
      route: '/apps/help-center'
    },
    {
      icon: icContacts,
      label: 'Contacts',
      route: '/apps/contacts/grid'
    },
    {
      icon: icAssessment,
      label: 'Scrumboard',
      route: '/apps/scrumboard/1'
    },
    {
      icon: icBook,
      label: 'Documentation',
      route: '/documentation'
    }
  ];

  pages: MegaMenuPage[] = [
    {
      label: 'All-In-One Table',
      route: '/apps/aio-table'
    },
    {
      label: 'Authentication',
      route: '/login'
    },
    {
      label: 'Components',
      route: '/ui/components/overview'
    },
    {
      label: 'Documentation',
      route: '/documentation'
    },
    {
      label: 'FAQ',
      route: '/pages/faq'
    },
    {
      label: 'Form Elements',
      route: '/ui/forms/form-elements'
    },
    {
      label: 'Form Wizard',
      route: '/ui/forms/form-wizard'
    },
    {
      label: 'Guides',
      route: '/pages/guides'
    },
    {
      label: 'Help Center',
      route: '/apps/help-center'
    },
    {
      label: 'Scrumboard',
      route: '/apps/scrumboard'
    }
  ];

  constructor(
    private popoverRef: PopoverRef<MegaMenuComponent>,
    private navigationService: NavigationService,
    private moduleService: ModuleService,
    ) {
      this.navigationService.getItem().subscribe(d => {
        this.navigationItems = d;
        console.log(this.navigationItems);
      });
    }

  ngOnInit() {
    
    console.log(this.navigationItems);
    if ((!this.navigationItems || this.navigationItems.length === 0) && this.navigationService.Items && this.navigationService.Items.length > 0) {
      this.navigationItems = this.navigationService.Items;
      console.log(this.navigationItems);
    } else {
      this.moduleService.getModulesProtected()
        .subscribe(response => {
          let sideBarLinks = [];
          const modules = response;

          sideBarLinks.push({
              label: 'Dashboard',
              type: 'link',
              icon: './assets/default/dashboard.png',
              children: [],
              route: '/dashboard',
              routerLinkActiveOptions: { exact: true }
          });
          modules.forEach((module) => {
              let parent = this.makeMenu(module);
              sideBarLinks.push(parent);
          });
          this.navigationItems = sideBarLinks;
          this.navigationService.setItem(this.navigationItems);
        });
    }
  }

  makeMenu(module){
    const parent = {
        label: module.Name,
        type: (module.Features && module.Features.length > 0) ? 'dropdown' : 'link',
        icon: module.Icon
    };
    if(module.Features?.length > 0){
        parent['children'] = [];
        module.Features.forEach(feature => {
            let child = this.makeMenu(feature);
            parent['children'].push(child);
        });
    } else {
        parent['route'] = module.Link;
    }
    return parent;
  }

  close() {
    this.popoverRef.close();
  }
}
