<div #originRef (click)="showPopover(originRef)" [class.bg-hover]="dropdownOpen"
  class="flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 pl-3 hover:bg-hover"
  matRipple>
  <div class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3" fxHide.xs>{{layoutService.userName}}</div>
  <div class="rounded-full h-9 w-9 flex items-center justify-center text-primary bg-primary-light">
    <mat-icon *ngIf="!layoutService.profilePicture || layoutService.profilePicture.length == 0" [icIcon]="icPerson">
    </mat-icon>
    <img @scaleIn *ngIf="layoutService.profilePicture"
      class="avatar h-15 w-18 flex-none align-start hidden sm:block border-3 border-white"
      [src]="layoutService.profilePicture" onError="this.src='assets/default/download.png'">
  </div>
</div>