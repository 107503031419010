import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InputControl } from '@vex/utils/input-control';
import { Icons } from '@vex/icons/icons';
import { HelperService } from '@vex/services/helper.service';
import { UserService } from '@vex/http-service/user.service';
import { Toast, ToastrService } from 'ngx-toastr';
import {FieldMandatory, Createsuccess, Updatesuccess} from '@vex/constants/settings';

@Component({
  selector: 'enable-fa',
  templateUrl: './enable-fa.component.html',
  styleUrls: ['./enable-fa.component.scss']
})

export class EnableFAComponent extends Icons implements OnInit {

  fa2Form: FormGroup;
  mode: 'Create' | 'Update' = 'Create';
  control: FormArray;
  error: any;
  disabledButton: boolean = false;
  disabledSendButton: boolean = false;
  phoneSpinner: boolean = false;
  smsDisable: boolean = false;
  showTimer: boolean = false;
  timeLeft: number = 10;
  interval;

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<EnableFAComponent>,
    private formBuilder: FormBuilder,
    private inputControl: InputControl,
    public helperService: HelperService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private userService: UserService,
  ) {
    super();
  }

  
  ngOnInit() {
    console.log(this.defaults);
    this.fa2Form = this.formBuilder.group({
      Phone: [this.defaults, Validators.required],
      SMS: ['', Validators.required],
    }, { updateOn: 'blur' });
  }

  inputState(formControl, form, attr) {
    return this.inputControl.isErrorState(formControl.controls[attr], form);
  }

  onPhone(value){
    if(!this.fa2Form.get('Phone').hasError('required')){
      this.phoneSpinner = true;
      this.userService.checkPhone(value).subscribe(response => {
        this.phoneSpinner = false;
        if(response)
          this.fa2Form.get('Phone').setErrors({duplicate: true});
      });
    } 
  }

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {   
        this.timeLeft--;
      } else {
        //this.showTimer = false;
        this.fa2Form.get('SMS').disable();
        this.disabledSendButton = false;
        this.timeLeft = 10;
        clearInterval(this.interval);
      }
      return;
    },1000)
  }

  sendCode(){
    this.disabledSendButton = true;
    this.fa2Form.get('SMS').enable();
    let value = this.fa2Form.value;
    let phone = value.Phone;
    console.log(phone);
    this.userService.sendCode(phone).subscribe(response => {
      
    });
    this.startTimer();
  }

  confirm(){
    if (!this.fa2Form.valid) {
      return;
    }
    const user = this.fa2Form.value;
    // const code = this.fa2Form.value;
    let data = {Email: '', EmailCode: '', PhoneNumber: user.Phone, SmsCode: user.SMS}
    console.log(data);
    this.disabledButton = true;
    this.userService.confirmCode(data).subscribe(response => {
    
      this.dialogRef.close(response);

    }, error => {
      this.disabledButton = false;
      this.error = error.error;
      console.log(this.error)
    });
  }
}
