export const UserInfoStorage = 'Info';
export const DateFormat = 'dd MMM, yyyy';
export const DateTimeFormat = 'dd MMM, yyyy hh:mm a';

export const IntRegex = '[+-]?[0-9]\\d*';
export const DecimalRegex = '^-?[0-9]\\d*(\\.\\d{1,4})?$';
export const ConfirmDialogTitle = 'Confirm Action';
export const ConfirmDialogMessage = 'Are you sure you want to delete these selected rule?';
export const ConfirmDialogMaxWidth = '400px';
export const PageSizeOptions: number[] = [5, 10, 20, 50];
export const PageSize = 10;
export const FieldMandatory = '* Fields are mandatory';
export const Createsuccess = 'Record created successfully!';
export const Updatesuccess = 'Record updated successfully!';
export const DeleteSuccess = 'Record deleted successfully!';
export const DeleteAllSuccess = 'All Record deleted successfully!';
export const NotUpdate = 'Application Component can\'t update';
export const invalidInventoryId = 'Invalid Inventory ID';
export const ChangeRequestSuccess = 'Change Request submitted successfully!';


// Delete Component
export const DeleteConfirmDialogTitle = 'Confirm Action';
export const DeleteConfirmDialogMessage = 'Are you sure you want to delete selected Component and its child components?';
export const DeleteConfirmDialogMaxWidth = '400px';

export const ThemeID = 'vex-layout-ikaros';