import { Component, OnInit } from '@angular/core';
import { Notification } from '../interfaces/notification.interface';
import { trackById } from '../../../../utils/track-by';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import icClearAll from '@iconify/icons-ic/twotone-clear-all';

import { NotificationService } from '@vex/http-service/notification.service';
import { NotificationView, ToastrType } from '@vex/model/notification-view';
import { Icons } from '@vex/icons/icons';
import { SignalRService } from '@vex/services/signal-r.service';
import { NotificationLocalService } from '@vex/services/local-notification.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent extends Icons implements OnInit {
  notifications: NotificationView[] = [];


  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icClearAll = icClearAll;
  icNotificationsOff = icNotificationsOff;
  trackById = trackById;

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private localNotification: NotificationLocalService) {
    super();
    // need to verify job
    // for new notification

  }

  newPage(link){
    window.open(link, '_blank'); // this.router.navigate([link]);
  }

  markAsRead(){
    this.notificationService.markAsRead(this.notifications.map(not=>not.Id)).subscribe(response => {
      console.log(response);
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.notifications = this.localNotification.notifications;

    this.localNotification.notificationUpdate
      .subscribe(response => {
        this.notifications = response.concat(this.notifications);
      });
  }

}