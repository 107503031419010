import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Notification } from '@vex/layout/toolbar/toolbar-notifications/interfaces/notification.interface'
import { NotificationService } from '@vex/http-service/notification.service';
import { NotificationView, ToastrType } from '@vex/model/notification-view';
import { SignalRService } from './signal-r.service';
import { Icons } from '@vex/icons/icons';
import { ToastrService } from 'ngx-toastr';
import { PushNotificationsService } from './push-notifications.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationLocalService extends Icons {
  constructor(
    private notificationService: NotificationService,
    private _notificationService: PushNotificationsService,
    private toastrService: ToastrService,
    public signal: SignalRService) {
    super(); 
    this._notificationService.requestPermission();
  }

  notifications: NotificationView[] = [];
  notificationUpdate = new Subject<NotificationView[]>();
  notificationCount = new Subject<number>();

  addNewNotification(newNotifications: NotificationView[]) {
    this.notifications = newNotifications.concat(this.notifications);
    this.notificationCount.next(this.notifications.filter(not => not.Read == true).length);
    this.notificationUpdate.next(newNotifications);
  }

  showPush(title: string, message: string) {
    let data: Array<any> = [];
    data.push({
      'title': title,
      'alertContent': message
    });
    this._notificationService.generateNotification(data);
  }

  FetchNotifications() {
    this.signal.getNotificationsBySignalR('NotificationMessage').subscribe(res => {
      let notify: NotificationView = res;
      let time: number = 3000;
      let html: boolean = false;
      let Message: string;
      notify.Message = notify.Message.trim();
      // if(notify.Message.length > 50)
      //   notify.Message = notify.Message.substr(0, 50)+"...";
      if (notify.Timeout > 0)
        time = notify.Timeout;
      this.showPush(notify.Title, notify.Message);
      Message = notify.Message;
      if (notify.Link != "") {
        html = true;
        Message = '<a href="' + notify.Link + '" target="_blank" style="text-decoration: underline">' + notify.Message + '</a>';
      }
      switch (ToastrType[notify.Type]) {
        case "Success":
          this.toastrService.success(Message, notify.Title, { timeOut: time, enableHtml: html });
          break;
        case "Info":
          this.toastrService.info(Message, notify.Title, { timeOut: time, enableHtml: html });
          break;
        case "Warning":
          this.toastrService.warning(Message, notify.Title, { timeOut: time, enableHtml: html });
          break;
        default:
          this.toastrService.error(Message, notify.Title, { timeOut: time, enableHtml: html });
          break;
      }
      this.assignIcons(notify);
      this.addNewNotification([notify]);
    });
    let filter = { PageNumber:  0, PageSize: 10, Sort: '', Order: '', Filter: ''}
    this.notificationService.getPaginatedPostData<NotificationView>(filter)
      .subscribe(response => {
        let newNotifications = response.items

        newNotifications.forEach(notification => {
          this.assignIcons(notification);
        });
        this.notifications = newNotifications;
        this.addNewNotification(newNotifications);
    });
  }


  assignIcons(notification: NotificationView) {
    if (notification.Type === ToastrType.Success) {
      notification.icon = this.icCheckCircle;
      notification.colorClass = 'text-green';
    } else if (notification.Type === ToastrType.Info) {
      notification.icon = this.icRoundInfo;
      notification.colorClass = 'text-primary';
    } else if (notification.Type === ToastrType.Warning) {
      notification.icon = this.icOutlineInfo;
      notification.colorClass = 'text-orange';
    } else if (notification.Type === ToastrType.Error) {
      notification.icon = this.icRoundClose;
      notification.colorClass = 'text-red';
    }
  }



}
