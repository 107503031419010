import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { BasePaginator } from '../model/base/base-model';

export class BaseService {
  baseUrl = environment.API_URL;
}

export abstract class IPaginatorService extends BaseService {
  abstract getPaginatedData<T>(sort: string, order: string, page: number, size: number, filter: string, params?: any): Observable<BasePaginator<T>>;
}
