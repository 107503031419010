import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  constructor() {}

  public getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  public deleteCookie(cookieName) {
    this.setCookie({ name: cookieName, value: '', expireDate: new Date(0).toUTCString()});
  }

  public setCookie(params: any) {
    let cookie: string = '';
    cookie += (params.name ? params.name : '') +'=';
    cookie += (params.value ? params.value : '') +';';
    cookie += 'expires=' + params.expireDate + ';';
    cookie += 'path='+ (params.path && params.path.length > 0 ? params.path : '/') +';';
    // cookie += location.protocol === 'https:' && params.secure && params.secure == true ? 'secure' : '';
    document.cookie = cookie;
  }
}