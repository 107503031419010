import { User } from '@vex/model/user';
import { Injectable } from '@angular/core';
import { BaseService, IPaginatorService } from './base';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BasePaginator } from '@vex/model/base/base-model';

@Injectable({
  providedIn: 'root'
})

export class UserService extends IPaginatorService {

  readonly baseEndpoint = 'Account';

  constructor(private http: HttpClient) {
    super();
  }

  getPaginatedData<User>(sort: string, order: string, page: number, size: number, filter: string): Observable<BasePaginator<User>> {

    let url = this.baseUrl + this.baseEndpoint + '/GetPaginated/' + page + '/' + size + '?';

    if (sort && order) {
      url += `sort=${sort}&order=${order}`;
    }
    if (filter) {
      url += (sort ? '&' : '') + `filter=${filter}`;
    }

    return this.http.get<BasePaginator<User>>(url);
  }

  getPaginatedPostData<User>(sort: string, order: string, page: number, size: number, filter: string = '', source: string[] = [], startDate: string = '', endDate: string = ''): Observable<BasePaginator<User>> {
    let url = this.baseUrl + this.baseEndpoint + '/GetPaginatedPost'
    let params = {
      Filter: filter,
      PageNumber: page,
      PageSize: size,
      Order: order,
      Sort: sort,
      Sources: source,
      StartDate: startDate, 
      EndDate: endDate
    }

    return this.http.post<BasePaginator<User>>(url, params);
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + this.baseEndpoint + '/GetUsers');
  }

  getUsersForSelect() : Observable<any[]>{
    return this.http.get<any[]>(this.baseUrl + this.baseEndpoint + '/GetUsersForSelect');
  }
  saveUser(user: User): Observable<User> {
    return this.http.post<User>(this.baseUrl + this.baseEndpoint + '/Register', user);
  }

  updateRoles(user: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + this.baseEndpoint + '/UpdateRoles', user);
  }

  importUser(user: User[]): Observable<User[]> {
    return this.http.post<User[]>(this.baseUrl + this.baseEndpoint + '/BulkImport', user);
  }

  getByActiveDirectoryId(Id: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + this.baseEndpoint + '/GetByActiveDirectoryId/' + Id);
  }

  GetByActiveDirectoryId

  updateUser(user: User): Observable<User> {
    return this.http.put<User>(this.baseUrl + this.baseEndpoint + '/' + user.Id + '?id=' + user.Id, user);
  }

  deleteUser(userId: string): Observable<User> {
    return this.http.delete<User>(this.baseUrl + this.baseEndpoint + '/' + userId);
  }

  deleteUsers(users: any[]): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + this.baseEndpoint + '/DeleteUsers', users);
  }

  checkEmail(email){
    return this.http.post<User>(this.baseUrl + this.baseEndpoint + '/CheckEmail/'+email, {});
  }

  checkPhone(phone){
    return this.http.post<User>(this.baseUrl + this.baseEndpoint + '/CheckPhoneNumber/'+phone, {});
  }

  saveCustomer(user: User): Observable<User> {
    return this.http.post<User>(this.baseUrl + this.baseEndpoint + '/RegisterUser', user);
  }

  forgotPassword(email: any) {
    return this.http.get<any>(this.baseUrl + this.baseEndpoint + '/ForgotPassword/'+email);
  }

  ResetPassword(obj: any) {
    return this.http.post<User>(this.baseUrl + this.baseEndpoint + '/ResetPassword', obj);
  }

  confirmCode(user: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + this.baseEndpoint + '/ConfirmWithCode', user);
  }

  sendCode(phone): Observable<any> {
    return this.http.post<any>(this.baseUrl + this.baseEndpoint + '/SendConfirmationSms/'+phone, {});
  }

  sendConfirmEmail(email): Observable<any> {
    return this.http.post<any>(this.baseUrl + this.baseEndpoint + '/SendConfirmationEmail/'+email, {});
  }

  changePassword(user: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + this.baseEndpoint + '/ChangePassword', user);
  }

  UpdateSessionTime(user: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + this.baseEndpoint + '/UpdateSessionTime', user);
  }
  
  getUserInfo(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + this.baseEndpoint + '/GetUserInfo');
  }

  updateloadImage(userId: string, file: File) {
    const formData = new FormData();
    formData.append('Id', userId.toString());
    formData.append('file', file, file.name);

    const params = new HttpParams();

    const options = {
      params,
      reportProgress: false,
    };

    return this.http.post<any>(this.baseUrl + this.baseEndpoint + '/ChangeDisplayPicture', formData, options);
  }
}