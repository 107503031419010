<div class="dropdown">
  <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <div class="dropdown-heading">Notifications</div>
      <div class="dropdown-subheading">You have {{ notifications.length }} new notifications.</div>
    </div>

    <!-- <button [matMenuTriggerFor]="settingsMenu" mat-icon-button type="button">
      <mat-icon [icIcon]="icSettings" class="notifications-header-icon"></mat-icon>
    </button> -->
  </div>

  <div class="dropdown-content">
    <a *ngFor="let notification of notifications; trackBy: trackById" [class.read]="notification.Read" [routerLink]="[]" class="notification" fxLayout="row" fxLayoutAlign="start center" matRipple>
      <div *ngIf="notification.Link == '';else alink" class="p-gutter">
        <ic-icon [icon]="notification.icon" [ngClass]="notification.colorClass" class="notification-icon" fxFlex="none" size="24px"></ic-icon>
        <div fxFlex="auto">
          <div class="notification-label">{{ notification.Title }}</div>
          <div class="notification-label">
            <p> {{ notification.Message | truncate: 250 }} </p>
          </div>
          <div class="notification-description">{{ notification.Datetime | relativeDateTime }}</div>
        </div>
        <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
      </div>
      <ng-template #alink>
        <div (click)="newPage(notification.Link)" class="p-gutter">
          <ic-icon [icon]="notification.icon" [ngClass]="notification.colorClass" class="notification-icon" fxFlex="none" size="24px"></ic-icon>
          <div fxFlex="auto">
            <div class="notification-label">{{ notification.Title }}</div>
            <div class="notification-label">
              <a style="text-decoration: underline">{{ notification.Message |
                truncate: 250 }}</a>
            </div>
            <div class="notification-description">{{ notification.Datetime | relativeDateTime }}</div>
          </div>
          <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
        </div>
      </ng-template>
    </a>
  </div>

  <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="center center">
    <button color="primary" fxFlex="50" mat-button type="button" (click)="markAsRead()">MARK ALL AS READ</button>
    <button color="primary" fxFlex="50" mat-button type="button" [routerLink]="['/application-settings/notifications']" routerLinkActive="router-link-active">See All</button>
  </div>
</div>

<!-- <mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon [icIcon]="icClearAll"></mat-icon>
    <span>Mark all as read</span>
  </button>

  <button mat-menu-item>
    <mat-icon [icIcon]="icNotificationsOff"></mat-icon>
    <span>Disable Notifications</span>
  </button>
</mat-menu> -->