import { Component, OnInit, Inject, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InputControl } from '@vex/utils/input-control';
import { Icons } from '@vex/icons/icons';
import { HelperService } from '@vex/services/helper.service';
import { Toast, ToastrService } from 'ngx-toastr';
import {FieldMandatory, Createsuccess, Updatesuccess} from '@vex/constants/settings';
import { UserService } from '@vex/http-service/user.service';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ChangePasswordComponent extends Icons implements OnInit {

  form: FormGroup;
  mode: 'Create' | 'Update' = 'Create';
  control: FormArray;
  error: any;
  inputType1 = 'password';
  inputType2 = 'password';
  inputType3 = 'password';
  visible1 = false;
  visible2 = false;
  visible3 = false;
  disabledSaveButton: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private formBuilder: FormBuilder,
    private inputControl: InputControl,
    public helperService: HelperService,
    private toastrService: ToastrService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
  ) {
    super();  
  }

  
  ngOnInit() {
    this.form = this.formBuilder.group({
      OldPassword: ['', Validators.required],
      NewPassword: ['', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).{8,}$')]],
      ConfirmPassword: ['', [Validators.required]],
    }, { updateOn: 'blur' });
  }

  inputState(formControl, form, attr) {
    return this.inputControl.isErrorState(formControl.controls[attr], form);
  }

  matchPassword(value){
    const customer = this.form.value;
    if(customer.NewPassword != value)
      this.form.get('ConfirmPassword').setErrors({match: true});
  }

  toggleVisibility(num) {
    if (this['visible'+num]) {
      this['inputType'+num] = 'password';
      this['visible'+num] = false;
      this.cd.markForCheck();
    } else {
      this['inputType'+num] = 'text';
      this['visible'+num] = true;
      this.cd.markForCheck();
    }
  }

  save(){
    if(!this.form.valid){
      this.toastrService.info(FieldMandatory);
      return;
    }
    this.disabledSaveButton =  true;
    let data = this.form.value;
    this.userService.changePassword(data).subscribe(res => {
      this.disabledSaveButton = false;
      this.dialogRef.close(res);
    },(error : any) => {
      this.disabledSaveButton = false;
      this.toastrService.error("Invalid Password");
    });
  }
}
