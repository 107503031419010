import { filter } from 'rxjs/operators';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Icons } from '@vex/icons/icons';
import { LayoutService } from '@vex/services/layout.service';
import { Subscription } from 'rxjs';
import { HelperService } from '@vex/services/helper.service';
@Component({
  selector: 'vex-chat-popup',
  templateUrl: './chat-popup.component.html',
  styleUrls: ['./chat-popup.component.scss']
})
export class ChatPopupComponent extends Icons implements OnInit  {

  chat: any = {};
  chatCache: any = {};
  workerUpdates: any[] = [];
  workerUpdatesCache: any[] = [];
  filterWorker: string = '';
  filterMessage: string = '';
  newSubscription: Subscription;
  selected: any[] = [];
  month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<ChatPopupComponent>,
    public helperService: HelperService,
    public layoutService: LayoutService,
  ) { 
    super();
  }

  ngOnInit() {
    this.displayMessages(this.layoutService.workerMessages);
    this.displayTab(this.workerUpdates[0], 0);
    this.workerUpdatesCache = this.workerUpdates;
    this.newSubscription = this.layoutService.messageCount.subscribe((index) => {
      let message = this.layoutService.workerMessages[index - 1];
      message.IsRead = true;
      this.showMessages(message);
      if(this.filterWorker == '') {
        this.workerUpdatesCache = this.workerUpdates;
      }
    });
  }

  onSearch() {
    this.filterWorker = this.filterWorker.toLowerCase();
    if(this.filterWorker == '') {
      this.workerUpdatesCache = this.workerUpdates;
    } else {
      this.workerUpdatesCache = this.workerUpdates.filter(worker => worker.WorkerNodeId.toLowerCase().includes(this.filterWorker));
    }
  }

  applyFilter() {
    this.filterMessage = this.filterMessage.toLowerCase();
    if(this.filterMessage == '') {
      this.chat = this.chatCache;
    } else {
      let filterData = JSON.parse(JSON.stringify(this.chatCache));
      filterData.Dates.forEach(date => {
        date.Messages = date.Messages.filter(message => message.Message.toLowerCase().includes(this.filterMessage));
      });
      this.chat = filterData;
    }
  }

  displayMessages(workerMessages) {
    workerMessages.forEach(message => {
      message.IsRead = true;
      this.showMessages(message);
    })
  }

  ngOnDestroy() {
    this.newSubscription?.unsubscribe();
  }

  displayTab(data, index) {
    this.selectedTab(index);
    this.chat = data;
    this.chatCache = data;
  }

  selectedTab(index){
    this.workerUpdates.forEach((e, i) => {
      this.selected[i] = false;
    });
    this.selected[index] = true;
  }

  getHeight() {
    let navHeight = 270;
    const height_inner = (window.innerHeight - navHeight).toString();
    return height_inner + 'px';
  }

  onClose(){
    this.dialogRef.close();
  }

  showMessages(node) {
    let workerIndex = this.workerUpdates.findIndex(x => x.WorkerNodeId == node.WorkerNodeId);
    let getDateDiff = this.dateDiff(node.Timestamp);
    if(workerIndex < 0) {
      this.workerUpdates.push( {
        WorkerNodeId: node.WorkerNodeId,
        Timestamp: getDateDiff.Timestamp,
        IsOnline: getDateDiff.IsOnline,
        Dates: []
      })
      workerIndex = this.workerUpdates.length - 1;
    }
    let date = new Date(node.Timestamp);
    let makeDate = this.month[date.getMonth()]+' '+date.getDate()+', '+date.getFullYear();
    this.workerUpdates[workerIndex].Timestamp = getDateDiff.Timestamp;
    this.workerUpdates[workerIndex].IsOnline = getDateDiff.IsOnline;
    let Dates = this.workerUpdates[workerIndex].Dates;
    let DateIndex = Dates.findIndex(x => x.Date == makeDate);
    if(DateIndex < 0) {
      Dates.push( {
        Date: makeDate,
        Messages: []
      });
      DateIndex = Dates.length - 1;
    }
    let Messages = Dates[DateIndex].Messages;
    let hours = date.getHours();
    let minutes = date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes();
    let time = (hours > 12 ? hours-12 : hours)+':'+minutes+' '+(hours > 12 ? 'PM' : 'AM');
    if(this.filterMessage != '' || node.Message.toLowerCase().includes(this.filterMessage)) {
      Messages.push({
        Message: node.Message,
        Timestamp: time
      })
    }
  }

  dateDiff(date){
    let timestamp = new Date(date).getTime();
    let d: any = Math.abs(timestamp - new Date().getTime()) / 1000;                 // delta
    let r: any = {};                                                                // result
    let s: any = {                                                                  // structure
        year: 31536000,
        month: 2592000,
        week: 604800, // uncomment row to ignore
        day: 86400,   // feel free to add your own row
        hour: 3600,
        minute: 60,
        second: 1
    };

    Object.keys(s).forEach(function(key){
        r[key] = Math.floor(d / s[key]);
        d -= r[key] * s[key];
    });
    if(r.week > 0) {
      let week = r.week+' week'+(r.week > 1 ? 's': '')+' ago';
      return {Timestamp: week, IsOnline: false};
    }
    if(r.day > 0) {
      let day = r.day+' day'+(r.hour > 1 ? 's': '')+' ago';
      return {Timestamp: day, IsOnline: false};
    }
    if(r.hour > 0) {
      let hour = r.hour+' hour'+(r.hour > 1 ? 's': '')+' ago';
      return {Timestamp: hour, IsOnline: false};
    }
    if(r.minute > 0) {
      let minute = r.minute+' minute'+(r.minute > 1 ? 's': '')+' ago';
      return {Timestamp: minute, IsOnline: r.minute < 5 ? true : false };
    }
    if(r.second > 0) {
      let second = r.hour+' second'+(r.second > 1 ? 's': '')+' ago';
      return {Timestamp: second, IsOnline: true};
    }
    return {};
  }
}
