<form #f="ngForm" [formGroup]="form">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="headline m-0" fxFlex="auto">Change Password</h2>

    <button class="text-secondary" (click)="helperService.confirmationScreen(dialogRef)" mat-icon-button type="button">
      <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
  </div>

  <mat-divider class="-mx-6 text-border"></mat-divider>

  <mat-dialog-content class="mt-3" fxLayout="column">
    <mat-form-field fxFlex>
      <mat-label>Old Password <span class="validation-red-color">*</span></mat-label>
      <input [type]="inputType1" formControlName="OldPassword" matInput>
      <mat-icon *ngIf="visible1" (click)="toggleVisibility(1)" [icIcon]="icVisibility"
          matTooltip="Toggle Visibility" matSuffix></mat-icon>
      <mat-icon *ngIf="!visible1" (click)="toggleVisibility(1)" [icIcon]="icVisibilityOff"
          matTooltip="Toggle Visibility" matSuffix></mat-icon>
      <!-- <mat-hint>Click the eye to toggle visibility</mat-hint> -->
      <mat-error class="validation-red-color" *ngIf="form.controls.OldPassword.hasError('required')">
        Old Password is required
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>New Password <span class="validation-red-color">*</span></mat-label>
      <input [type]="inputType2" formControlName="NewPassword" matInput>
      <mat-icon *ngIf="visible2" (click)="toggleVisibility(2)" [icIcon]="icVisibility"
          matTooltip="Toggle Visibility" matSuffix></mat-icon>
      <mat-icon *ngIf="!visible2" (click)="toggleVisibility(2)" [icIcon]="icVisibilityOff"
          matTooltip="Toggle Visibility" matSuffix></mat-icon>
      <mat-icon [icIcon]="icClear" class="mr-3 red-color" matPrefix *ngIf="form.controls.NewPassword.hasError('pattern')"></mat-icon>
      <mat-icon [icIcon]="icCheck" class="mr-3 green-color" matPrefix *ngIf="!form.controls.NewPassword.hasError('pattern') && !form.controls.NewPassword.hasError('required')"></mat-icon>
      <!-- <mat-hint>Click the eye to toggle visibility</mat-hint> -->
      <mat-error class="validation-red-color" *ngIf="form.controls.NewPassword.hasError('required')">
        Oops! We need New Password
      </mat-error>
      <mat-error class="validation-red-color" *ngIf="form.controls.NewPassword.hasError('pattern')">
        We care for your security. Please choose a stronger password like Kum12@-o.
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>Confirm Password <span class="validation-red-color">*</span></mat-label>
      <input [type]="inputType3" formControlName="ConfirmPassword" matInput (blur)="matchPassword($event.target.value)">
      <mat-icon *ngIf="form.controls.ConfirmPassword.hasError('match')" [icIcon]="icClear" class="mr-3 red-color" matPrefix></mat-icon>
      <mat-icon *ngIf="!form.controls.ConfirmPassword.hasError('match') && !form.controls.ConfirmPassword.hasError('required')" [icIcon]="icCheck" class="mr-3 green-color" matPrefix></mat-icon>
      <mat-icon *ngIf="visible3" (click)="toggleVisibility(3)" [icIcon]="icVisibility"
          matTooltip="Toggle Visibility" matSuffix></mat-icon>
      <mat-icon *ngIf="!visible3" (click)="toggleVisibility(3)" [icIcon]="icVisibilityOff"
          matTooltip="Toggle Visibility" matSuffix></mat-icon>
      <!-- <mat-hint>Click the eye to toggle visibility</mat-hint> -->
      <mat-error class="validation-red-color" *ngIf="form.controls.ConfirmPassword.hasError('required')">
        Confirn Password is required
      </mat-error>
      <mat-error class="validation-red-color" *ngIf="form.controls.ConfirmPassword.hasError('match')">
        Password entries do not match
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-divider class="-mx-6 text-border"></mat-divider>
  <mat-dialog-actions align="end">
    <p class="validation-margin-right"><span class="validation-red-color">*</span> Fields are mandatory</p>
    <button mat-button (click)="helperService.confirmationScreen(dialogRef)" type="button">CANCEL</button>
    <button type="button" color="primary" mat-raised-button (click)="save()" [disabled]="disabledSaveButton">
      Change 
      <mat-icon *ngIf="disabledSaveButton">
        <mat-spinner color="accent" diameter="20">
        </mat-spinner>
      </mat-icon>
    </button>
  </mat-dialog-actions>
</form>