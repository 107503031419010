import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  isStoretoSession: boolean = false;
  MigrationSourceInventoryId: any;
  MigrationTargetInventory: any;
  MigrationTargetAccount: any;
  MigrationTargetResource: any;

  constructor(
  ) {
    this.getAllKeysFromSession();
  }

  setItem(key, value) {
    this[key] = value;
  }

  getItem(key) {
    return this[key];
  }

  removeItem(key) {
    this[key] = '';
  }

  getAllKeysFromSession() {
    if (localStorage.getItem('MigrationSourceInventoryId')) {
        this.setItem('MigrationSourceInventoryId', JSON.parse(localStorage.getItem('MigrationSourceInventoryId')));
    }
    if (localStorage.getItem('MigrationTargetInventory')) {
        this.setItem('MigrationTargetInventory', JSON.parse(localStorage.getItem('MigrationTargetInventory')));
    }
    if (localStorage.getItem('MigrationTargetAccount')) {
        this.setItem('MigrationTargetAccount', JSON.parse(localStorage.getItem('MigrationTargetAccount')));
    }
    if (localStorage.getItem('MigrationTargetResource')) {
        this.setItem('MigrationTargetResource', JSON.parse(localStorage.getItem('MigrationTargetResource')));
    }
    localStorage.removeItem('MigrationSourceInventoryId');
    localStorage.removeItem('MigrationTargetInventory');
    localStorage.removeItem('MigrationTargetAccount');
    localStorage.removeItem('MigrationTargetResource');
  }

  setAllkeysToSession() {
    if(this.MigrationSourceInventoryId) {
      localStorage.setItem('MigrationSourceInventoryId', JSON.stringify(this.MigrationSourceInventoryId));
    }
    if(this.MigrationTargetInventory) {
      localStorage.setItem('MigrationTargetInventory', JSON.stringify(this.MigrationTargetInventory));
    }
    if(this.MigrationTargetAccount) {
      localStorage.setItem('MigrationTargetAccount', JSON.stringify(this.MigrationTargetAccount));
    }
    if(this.MigrationTargetResource) {
      localStorage.setItem('MigrationTargetResource', JSON.stringify(this.MigrationTargetResource));
    }     
  }
}
