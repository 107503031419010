import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";
import { DateFormat, DateTimeFormat } from '@vex/constants/settings';

@Pipe({
    name: "dateFormat"
})
export class DateFormatPipe implements PipeTransform {
    constructor(public datepipe: DatePipe) { }
    transform(value: any, format = 'DT'): string {
        if (value && value !== "null") {

            if (format==='D') {
                return this.datepipe.transform(value, DateFormat);
            }else if (format==='DT') {
                return this.datepipe.transform(value, DateTimeFormat);
            }
        }
    }
}
