import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';
import { AuthGuard } from '@vex/authentication/auth/auth.guard';
import { CookieService } from '@vex/services/cookie.service';

const routes: VexRoutes = [
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./auth/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule)
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./auth/reset-password/reset-password.module').then((m) => m.ResetPasswordModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./auth/register/register.module').then((m) => m.RegisterModule)
  },
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        canActivate: [AuthGuard],
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        canActivate: [AuthGuard],
        path: 'unauthorized',
        loadChildren: () => import('./error-page/error-page.module').then((m) => m.ErrorPageModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'onboard',
        loadChildren: () => import('./onboard/onboard.module').then((m) => m.OnBoardModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'dashboard',
        loadChildren: () => import('./dashboards/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'security',
        loadChildren: () => import('./security/security.module').then((m) => m.SecurityModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'deployment',
        loadChildren: () => import('./deployment/deployment.module').then((m) => m.DeploymentModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'application-settings',
        loadChildren: () =>
          import('./application-settings/application-settings.module').then(
            (m) => m.ApplicationSettingsModule
          )
      },
      {
        canActivate: [AuthGuard],
        path: 'network-management',
        loadChildren: () =>
          import('./network-management/network-management.module').then(
            (m) => m.NetworkManagementModule
          )
      },
      {
        canActivate: [AuthGuard],
        path: 'blueprintdesigner',
        loadChildren: () =>
          import('./blue-print-designer/blue-print-designer.module').then(
            (m) => m.BluePrintDesignerModule
          )
      },
      {
        canActivate: [AuthGuard],
        path: 'platform-settings',
        loadChildren: () =>
          import('./platform-settings/platform-settings.module').then(
            (m) => m.PlatformSettingsModule
          )
      },
      {
        canActivate: [AuthGuard],
        path: 'operations',
        loadChildren: () => import('./operations/operations.module').then((m) => m.OperationsModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'compliance',
        loadChildren: () => import('./compliance/compliance.module').then((m) => m.ComplianceModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'user-setting',
        loadChildren: () =>
          import('./user-setting/user-setting.module').then((m) => m.UserSettingModule)
      }
      // {
      //   path: '**',
      //   loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      // }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'corrected',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule],
  providers: [CookieService]
})
export class AppRoutingModule {}
