import { Component, OnInit, Inject, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InputControl } from '@vex/utils/input-control';
import { Icons } from '@vex/icons/icons';
import { HelperService } from '@vex/services/helper.service';
import { Toast, ToastrService } from 'ngx-toastr';
import {FieldMandatory, Createsuccess, Updatesuccess} from '@vex/constants/settings';
import { UserService } from '@vex/http-service/user.service';
import { AuthService } from '@vex/http-service/auth.service';

@Component({
  selector: 'session-time',
  templateUrl: './session-time.component.html',
  styleUrls: ['./session-time.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class SessionTimeComponent extends Icons implements OnInit {

  form: FormGroup;
  mode: 'Create' | 'Update' = 'Create';
  control: FormArray;
  disabledSaveButton: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<SessionTimeComponent>,
    private formBuilder: FormBuilder,
    private inputControl: InputControl,
    public helperService: HelperService,
    private toastrService: ToastrService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
  ) {
    super();  
  }

  
  ngOnInit() {
    let Hour = 0;let Minute = 15; let Second = 0;
    if (this.defaults) {
        this.mode = 'Update';
        let second = this.defaults.SessionTime ? Number(this.defaults.SessionTime) : 900
        let duration = this.helperService.getDuration(second);
        Hour = duration.hour;
        Minute = duration.minute;
        Second = duration.second;
    }

    this.form = this.formBuilder.group({
      Hour: [Hour, [Validators.max(23), Validators.min(0),Validators.required]],
      Minute: [Minute, [Validators.max(59), Validators.min(0),Validators.required]],
      Second: [Second, [Validators.max(59), Validators.min(0),Validators.required]],
    }, { updateOn: 'blur' });
  }

  inputState(formControl, form, attr) {
    return this.inputControl.isErrorState(formControl.controls[attr], form);
  }

  save(){
    if(!this.form.valid){
      this.toastrService.info(FieldMandatory);
      return;
    }
    
    this.disabledSaveButton =  true;
    let form = this.form.value;
    let getSeconds = form.Hour * 3600 + form.Minute * 60 + form.Second;
    let data = {};
    data['UserId'] = this.defaults.Id;
    data['SessionTime'] = getSeconds.toString();
    this.userService.UpdateSessionTime(data).subscribe(res => {
      localStorage.setItem('token', res['Token']);
      this.authService.storeRefreshToken(res['RefreshToken']);
      localStorage.setItem('token', res['Token']);
      localStorage.setItem('SessionTime', res['SessionTime']);
      this.disabledSaveButton = false;
      this.dialogRef.close(res);
    },(error : any) => {
      this.disabledSaveButton = false;
      this.toastrService.error("Invalid Session");
    });
  }
}
