import { Injectable } from '@angular/core';
import { BaseService, IPaginatorService } from './base';
import { Observable } from 'rxjs';
import { DateTime } from 'luxon';
import { Notification } from './../layout/toolbar/toolbar-notifications/interfaces/notification.interface';
import { ToastrType } from '@vex/model/notification-view';
import { BasePaginator, Paginator } from '@vex/model/base/base-model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends IPaginatorService {
  readonly baseEndpoint = 'Notification/';
  constructor(private http: HttpClient) {
    super();
  }

  getPaginatedData<Notification>(sort: string, order: string, page: number, size: number, filter: string, params?: any): Observable<BasePaginator<Notification>> {
    let url = this.baseUrl + this.baseEndpoint + 'GetPaginated/' + page + '/' + size + '?';

    if (sort && order) {
      url += `sort=${sort}&order=${order}`;
    }
    if (filter) {
      url += (sort ? '&' : '') + `filter=${filter}`;
    }

    return this.http.get<BasePaginator<Notification>>(url);
  }

  getPaginatedPostData<Notification>(param: Paginator): Observable<BasePaginator<Notification>> {
    let url = this.baseUrl + this.baseEndpoint + 'GetPaginated'
    return this.http.post<BasePaginator<Notification>>(url, param);
  }

  markAsRead(notifications = []): Observable<any> {
    return this.http.post<any>(this.baseUrl + this.baseEndpoint + 'MarkRead', notifications);
  }

}
