import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import icBookmarks from '@iconify/icons-ic/twotone-bookmarks';
import emojioneUS from '@iconify/icons-emojione/flag-for-flag-united-states';
import emojioneDE from '@iconify/icons-emojione/flag-for-flag-germany';
import icMenu from '@iconify/icons-ic/twotone-menu';
import { ConfigService } from '../../services/config.service';
import { map } from 'rxjs/operators';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icAssignmentTurnedIn from '@iconify/icons-ic/twotone-assignment-turned-in';
import icBallot from '@iconify/icons-ic/twotone-ballot';
import icDescription from '@iconify/icons-ic/twotone-description';
import icAssignment from '@iconify/icons-ic/twotone-assignment';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icDoneAll from '@iconify/icons-ic/twotone-done-all';
import { NavigationService } from '../../services/navigation.service';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import { PopoverService } from '@vex/components/popover/popover/popover.service';
import { MegaMenuComponent } from '@vex/components/popover/mega-menu/mega-menu.component';
import icSearch from '@iconify/icons-ic/twotone-search';
import { Icons } from '@vex/icons/icons';
import { NavigationLink } from '@vex/interfaces/navigation-item.interface';
import { ModuleService } from '@vex/http-service/module.service';
import { Router } from '@angular/router';

@Component({
    selector: 'vex-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent extends Icons implements OnInit {
    @Input() mobileQuery: boolean;

    @Input()
    @HostBinding('class.shadow-b')
    hasShadow: boolean;
    showHeader: boolean = true;
    navigationItems = [];
    imageUrl$ = this.configService.config$.pipe(map((config) => config.sidenav.imageUrl));
    isHorizontalLayout$ = this.configService.config$.pipe(
        map((config) => config.layout === 'horizontal')
    );
    isVerticalLayout$ = this.configService.config$.pipe(
        map((config) => config.layout === 'vertical')
    );
    isNavbarInToolbar$ = this.configService.config$.pipe(
        map((config) => config.navbar.position === 'in-toolbar')
    );
    isNavbarBelowToolbar$ = this.configService.config$.pipe(
        map((config) => config.navbar.position === 'below-toolbar')
    );

    icSearch = icSearch;
    icBookmarks = icBookmarks;
    emojioneUS = emojioneUS;
    emojioneDE = emojioneDE;
    icMenu = icMenu;
    icPersonAdd = icPersonAdd;
    icAssignmentTurnedIn = icAssignmentTurnedIn;
    icBallot = icBallot;
    icDescription = icDescription;
    icAssignment = icAssignment;
    icReceipt = icReceipt;
    icDoneAll = icDoneAll;
    icArrowDropDown = icArrowDropDown;

    constructor(
        public layoutService: LayoutService,
        private configService: ConfigService,
        private navigationService: NavigationService,
        private moduleService: ModuleService,
        private router: Router,
        private popoverService: PopoverService
    ) {
        super();
        this.navigationService.getItem().subscribe((d) => {
            this.navigationItems = d;
        });
    }

    ngOnInit() {
        if (
            (!this.navigationItems || this.navigationItems.length === 0) &&
            this.navigationService.Items &&
            this.navigationService.Items.length > 0
        ) {
            this.navigationItems = this.navigationService.Items;
        } else {
            this.moduleService.getModules().subscribe((response) => {
                let sideBarLinks = [];
                const modules = response;

                sideBarLinks.push({
                    label: 'Dashboard',
                    type: 'link',
                    icon: './assets/default/dashboard.png',
                    children: [],
                    route: '/dashboard',
                    routerLinkActiveOptions: { exact: true }
                });
                modules.forEach((module) => {
                    let parent = this.makeMenu(module);
                    sideBarLinks.push(parent);
                });
                this.navigationItems = sideBarLinks;
                this.navigationService.setItem(this.navigationItems);
            });
        }
    }

    makeMenu(module){
        const parent = {
            label: module.Name,
            type: (module.Features && module.Features.length > 0) ? 'dropdown' : 'link',
            icon: module.Icon
        };
        if(module.Features?.length > 0){
            parent['children'] = [];
            module.Features.forEach(feature => {
                let child = this.makeMenu(feature);
                parent['children'].push(child);
            });
        } else {
            parent['route'] = module.Link;
        }
        return parent;
    }

    openQuickpanel() {
        this.layoutService.openQuickpanel();
    }

    openSidenav() {
        this.layoutService.openSidenav();
    }

    openMegaMenu(origin: ElementRef | HTMLElement) {
        this.popoverService.open({
            content: MegaMenuComponent,
            origin,
            offsetY: 10,
            position: [
                {
                    originX: 'start',
                    originY: 'bottom',
                    overlayX: 'start',
                    overlayY: 'top'
                },
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'top'
                }
            ]
        });
    }

    openSearch() {
        this.layoutService.openSearch();
    }
}
