import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Icons } from '@vex/icons/icons';
@Component({
  selector: 'vex-session-dialog',
  templateUrl: './session-dialog.component.html',
  styleUrls: ['./session-dialog.component.scss']
})
export class SessionDialogComponent extends Icons {
  count = 0; 
  max_count = 30; 
  remaining_time: number = 30;
  timer: any;

  constructor(public dialogRef: MatDialogRef<SessionDialogComponent>) {
    super();
    let scope = this;
    this.count = 0;
    if(localStorage.getItem('sessionTimeOut')) {
      this.max_count = Number(localStorage.getItem('sessionTimeOut'));
      this.remaining_time = Number(localStorage.getItem('sessionTimeOut'));
    }
    this.timer = setInterval(() => {
      scope.timedCount()
    }, 1000)
  }

  timedCount() {
    this.count = this.count + 1;
    this.remaining_time = this.max_count - this.count;
    localStorage.setItem('sessionTimeOut', this.remaining_time.toString());
    if(localStorage.getItem('sessionConfirmation')) {
      if(this.remaining_time == 0){
        this.onDismiss();
      }
    } else {
      this.onConfirm();
    }
  }

  onConfirm(): void {
    clearInterval(this.timer);
    localStorage.removeItem('sessionTimeOut');
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    clearInterval(this.timer);
    localStorage.removeItem('sessionTimeOut');
    this.dialogRef.close(false);
  }
}
