export class Base<T> {
    _id: string;
    Id: T;
    Active: boolean;
}

export class BaseModel<T> extends Base<T> {
    CreatedDate?: Date;
    CreatedBy?: string;
    UpdatedDate?: Date;
    UpdatedBy?: string;
}

export interface BasePaginator<T> {
    totalCount: number;
    items: T[];
    state?: number;
}

export class Paginator {
    Sort: string;
    Filter: string;
    Filters?: any;
    PriceRange?: string;
    Order: string;
    PageSize: number;
    PageNumber: number;
}

export class KeyValue<T, V> {
    Id: T;
    Value: V;
}

export class BaseVersion<T> extends BaseModel<T> {
    //All version of same Content will have same Record Group Id
    RecordGroupId: string;
    PreviousVersionId: string;
    ApplicationUserId: string;
    ReviewedByUserId: string;
    LastCommitMessage: string;
    LastCommitUserId: string;
    Version: string;
    SubmitForApproval: boolean;
    State: State;
}

export enum State {
    Initiated,
    PendingApproval,
    Approved,
    LaunchInProgress,
    RunningOnCloud,
    Rejected,
    Deleted,
    ErrorOnCloud,
    SyncInProgress,
    OutOfSync,
    RunningOnCloudDrifted,
    DeleteInProgress,
    InQueue,
    DeleteInQueue,
    Locked
}
