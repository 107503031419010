import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as signalR from "@microsoft/signalr";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SignalRService {

  public hubConnection: signalR.HubConnection

  constructor() {
  }

  public initialize(){
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.API_URL)
      .build();

    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err));
  }
  
  public getDataBySignalR(job): Observable<any> {

    const obs = new Observable((observe) => {
      this.hubConnection.on(job, (data) => {
        observe.next(data);
        observe.complete;
      });
    })

    return obs;
  }

  public getDataBySignalRArray(job): Observable<any> {

    const obs = new Observable((observe) => {
      this.hubConnection.on(job, (...data) => {
        observe.next(data);
        observe.complete;
      });
    })

    return obs;
  }

  public getNotificationsBySignalR(job): Observable<any> {

    const obs = new Observable((observe) => {
      this.hubConnection.on(job, (data) => {
        observe.next(data);
      });
    })

    return obs;
  }
}
