<div class="w-full h-full" fxLayout="column">
  <mat-drawer-container class="overflow-hidden" fxFlex="auto">
    <mat-drawer class="drawer"
                position="start"
                mode="side" opened>
      <div class="h-full" fxLayout="column">
        <div class="p-4 border-b bg-app-bar"
              fxFlex="none"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="16px">
          <div class="border rounded-full text-hint overflow-hidden bg-card"
                fxFlex="auto"
                fxLayout="row"
                fxLayoutAlign="start center">
            <mat-icon [icIcon]="icSearch" class="ml-4 mr-3 mt-1" height="18px" width="18px"></mat-icon>
            <input class="outline-none border-0 h-10 w-full placeholder:text-secondary caption bg-card"
                    fxFlex="auto"
                    placeholder="Search"
                    [(ngModel)]="filterWorker"
                    (keyup)="onSearch()"
                    type="text">
          </div>
        </div>

        <h2 class="headline font-normal m-0 p-4 text-primary border-b" fxFlex="none" fxLayout="row">
            <span class="block mr-4">
              <img class="avatar worker-img bg-primary" fxFlex="none" src="assets/img/worker.png">
            </span>
          <span class="block font-semibold">Worker Updates</span>
        </h2>

        <vex-scrollbar class="flex-auto">
          <a *ngFor="let node of workerUpdatesCache; let i = index;"
              class="border-b px-4 py-4 hover:bg-hover cursor-pointer relative select-none block no-underline text-dark"
              [class.bg-hover]="selected[i]"
              (click)="displayTab(node, i)">
            <div fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="16px">
              <div class="circle-icon bg-green" *ngIf="node.IsOnline; else offlineIcon1">
                <mat-icon [icIcon]="icDone"></mat-icon>
              </div>
              <ng-template #offlineIcon1>
                <div class="circle-icon bg-red">
                </div>
              </ng-template>
              <div class="overflow-hidden" fxFlex="auto">
                <div class="subheading-2 leading-snug">{{ node.WorkerNodeId }}</div>
              </div>
              <div fxFlex="none" fxLayout="column" fxLayoutAlign="center end">
                <div class="caption font-medium text-secondary">{{ node.Timestamp }}</div>
              </div>
            </div>
          </a>
        </vex-scrollbar>
      </div>
    </mat-drawer>
  
    <mat-drawer-content class="chat-pattern relative">
      <div class="w-full h-full overflow-hidden" fxLayout="column">
        <div class="bg-app-bar p-4 border-b" fxFlex="none" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
          <div class="circle-icon bg-green" *ngIf="chat.IsOnline; else offlineIcon">
            <mat-icon [icIcon]="icDone"></mat-icon>
          </div>
          <ng-template #offlineIcon>
            <div class="circle-icon bg-red">
            </div>
          </ng-template>
      
          <div fxFlex="auto">
            <div class="subheading-1">{{ chat.WorkerNodeId }}</div>
            <div class="caption leading-none text-green" *ngIf="chat.IsOnline; else offline">Online</div>
            <ng-template #offline>
              <div class="caption leading-none text-red">Offline</div>
            </ng-template>
          </div>
      
          <button style="width: 38px; height: 38px; line-height: 38px;" (click)="helperService.confirmationScreen(dialogRef)" mat-icon-button type="button">
            <mat-icon [icIcon]="icClose" class="text-secondary"></mat-icon>
          </button>
        </div>
        <div class="p-4 overflow-auto" [ngStyle]="{'height': getHeight()}">
          <vex-scrollbar class="flex-auto">
            <ng-container *ngFor="let date of chat.Dates">
              <div class="text-center">
                <h5 class="text-secondary text-xxs">{{ date.Date }}</h5>
              </div>
              <ng-container *ngFor="let message of date.Messages">
                <div class="max-w-3/4 partner" fxFlexAlign="start" fxLayout="row">
                  <div class="m-1 mr-0 py-2 px-4 shadow-1 rounded-lg bg-card">
                    <h4 class="body-1 m-0">{{ message.Message }}
                    </h4>
                    <h5 class="text-secondary text-xxs">{{ message.Timestamp }}</h5>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </vex-scrollbar>
        </div>
        
        <div class="p-4 bg-app-bar border-t">
            <div class="border rounded-full text-hint overflow-hidden bg-card ltr:pl-6 rtl:pr-6"
                 fxFlex="auto"
                 fxLayout="row"
                 fxLayoutAlign="start center">
              <input class="outline-none border-0 h-12 w-240 placeholder:text-secondary body-1 bg-card"
                     fxFlex="auto"
                     placeholder="Search..."
                     [(ngModel)]="filterMessage"
                     (keyup)="applyFilter()"
                     type="text">
              <button  class="ltr:mr-4 rtl:ml-4 ltr:ml-3 rtl:mr-3"
                      mat-icon-button
                      type="button"
                      >
                <mat-icon [icIcon]="icSearch"></mat-icon>
              </button>
            </div>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
