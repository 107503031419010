<form #f="ngForm" [formGroup]="form">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="headline m-0" fxFlex="auto">Session Time</h2>

    <button class="text-secondary" (click)="helperService.confirmationScreen(dialogRef)" mat-icon-button type="button">
      <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
  </div>

  <mat-divider class="-mx-6 text-border"></mat-divider>

  <mat-dialog-content class="mt-3" fxLayout="column">
    <mat-form-field fxFlex>
      <mat-label>Hours</mat-label>
      <input type="number" formControlName="Hour" matInput min='0' max='23'>
      <mat-error class="validation-red-color" *ngIf="inputState(form, f, 'Hour')">
        <strong>Limit</strong> 0 to 23
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>Minutes</mat-label>
      <input type="number" formControlName="Minute" matInput min='0' max='59'>
      <mat-error class="validation-red-color" *ngIf="inputState(form, f, 'Minute')">
        <strong>Limit</strong> 0 to 59
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>Seconds</mat-label>
      <input type="number" formControlName="Second" matInput min='0' max='59'>
      <mat-error class="validation-red-color" *ngIf="inputState(form, f, 'Second')">
        <strong>Limit</strong> 0 to 59
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-divider class="-mx-6 text-border"></mat-divider>
  <mat-dialog-actions align="end">
    <p class="validation-margin-right"><span class="validation-red-color">*</span> Fields are mandatory</p>
    <button mat-button (click)="helperService.confirmationScreen(dialogRef)" type="button">CANCEL</button>
    <button type="button" color="primary" mat-raised-button (click)="save()" [disabled]="disabledSaveButton">
      Change 
      <mat-icon *ngIf="disabledSaveButton">
        <mat-spinner color="accent" diameter="20">
        </mat-spinner>
      </mat-icon>
    </button>
  </mat-dialog-actions>
</form>