import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';

@Component({
  selector: 'vex-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  // styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  title: string;
  message: string;
  note: string;
  notesBgColor: string;
  icClose = icClose;

  showDeleteAll = false;
  deleteAllMessage = '';

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.note = data.note;
    this.notesBgColor = data.notesBgColor;
    this.showDeleteAll = data.showDeleteAll;
    this.deleteAllMessage = data.deleteAllMessage;
  }

  onConfirm(data): void {
    // Close the dialog, return true
    this.dialogRef.close(data);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {

  constructor(public title: string, public message: string, public note = '', public showDeleteAll = false, public deleteAllMessage = '', public notesBgColor: string = 'primary') {
  }
}
