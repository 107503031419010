import { Icon } from '@visurel/iconify-angular';
import { DateTime } from 'luxon';
import { BaseModel } from './base/base-model';

export enum ToastrType {
    Success,
    Info,
    Warning,
    Error
}

export enum NotificationSource {
    CloudConnect,
    Provisioning,
    Migration,
    Discovery,
    SyncService
}

export class NotificationView extends BaseModel<string>{
    Source: NotificationSource;
    Link: string;
    Title: string;
    Message: string;
    Timeout: number;
    Type: ToastrType;

    colorClass?: string;
    Datetime?: DateTime;
    Read?: boolean;
    icon?: Icon;
}


export class Notification {
    Message: string;
    Date: Date;
    Type: ToastrType;
}
