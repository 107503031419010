import { Injectable } from '@angular/core';
import { BaseService, IPaginatorService } from './base';
import { Observable } from 'rxjs';
import { Module } from '../model/module';
import { HttpClient } from '@angular/common/http';
import { BasePaginator } from '@vex/model/base/base-model';

@Injectable({
  providedIn: 'root'
})
export class ModuleService extends IPaginatorService {

  readonly baseEndpoint = 'Module';

  constructor(private http: HttpClient) {
    super();
  }

  getPaginatedData<Module>(sort: string, order: string, page: number, size: number, filter: string): Observable<BasePaginator<Module>> {

    let url = this.baseUrl + this.baseEndpoint + '/GetPaginated/' + page + '/' + size + '?';

    if (sort && order) {
      url += `sort=${sort}&order=${order}`;
    }
    if (filter) {
      url += (sort ? '&' : '') + `filter=${filter}`;
    }

    return this.http.get<BasePaginator<Module>>(url);
  }

  getModules(): Observable<Module[]> {
    return this.http.get<Module[]>(this.baseUrl + this.baseEndpoint + '');
  }

  getModulesProtected(): Observable<Module[]> {
    return this.http.get<Module[]>(this.baseUrl + this.baseEndpoint + '/GetProtected');
  }

  addModule(module: Module): Observable<Module> {
    return this.http.post<Module>(this.baseUrl + this.baseEndpoint + '', module);
  }

  getModuleById(id: string): Observable<Module> {
    return this.http.get<Module>(this.baseUrl + this.baseEndpoint + '/GetById/' + id);
  }

  updateModule(moduleId: string, module: Module): Observable<Module> {
    return this.http.put<Module>(this.baseUrl + this.baseEndpoint + '/' + moduleId + '?id=' + moduleId, module);
  }

  deleteModules(modules: any[]): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + this.baseEndpoint + '/DeleteModules', modules);
  }

  deleteModule(moduleId: string): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + this.baseEndpoint + '/' + moduleId);
  }
}
