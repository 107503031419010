<div @transformPopover class="popover">
  <div style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate(59px, -62px);"></div>
  <ng-container [ngSwitch]="renderMethod">
    <div *ngSwitchCase="'text'" [innerHTML]="content"></div>
    <ng-container *ngSwitchCase="'template'">
      <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'component'">
      <ng-container *ngComponentOutlet="content"></ng-container>
    </ng-container>
  </ng-container>
</div>
