import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UserSettingRoutingModule } from './user-setting-routing.module';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SessionTimeComponent } from './session-time/session-time.component';
import { EnableFAComponent } from './enable-fa/enable-fa.component';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@visurel/iconify-angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
@NgModule({
  declarations: [UserProfileComponent, ChangePasswordComponent, EnableFAComponent, SessionTimeComponent],
  exports: [
    UserProfileComponent,
    ChangePasswordComponent,
    EnableFAComponent,
    SessionTimeComponent
  ],
  imports: [
    CommonModule,
    UserSettingRoutingModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    IconModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class UserSettingModule {
}
