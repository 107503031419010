<div (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async" class="sidenav flex flex-col">
  <div class="sidenav-toolbar flex-none flex items-center" style="padding: 0;">
    <div *ngIf="!collapsed">
      <img src="assets/img/demo/logo-dark.png" class="select-none flex-none"
        *ngIf="(style$ | async) === 'vex-style-light'" style="width: 7.5rem;">
      <img (click)="onClickImg()" [src]="imageUrl$ | async" class="select-none flex-none" *ngIf="(style$ | async) !== 'vex-style-light'"
        style="width: 7.5rem; cursor: pointer;">
    </div>
    <div *ngIf="collapsed">
      <h1 (click)="onClickImg()" class="" style="font-family: 'Audiowide';
  src: url('assets/font/Audiowide-Regular.ttf'); margin-left: 25px;height:46px;cursor: pointer;">K</h1>
      <!-- <img (click)="onClickImg()" src="assets/img/demo/logo-small.png" class="" style="width: 65px;max-width:65px;height:46px;cursor: pointer;"> -->
    </div>
    <!-- <h2 class="title ltr:pl-4 rtl:pr-4 select-none flex-auto">{{ title$ | async }}</h2> -->
    <button (click)="toggleCollapse()" *ngIf="showCollapsePin$ | async"
      class="w-8 h-8 ml-7 leading-none flex-none hidden lg:block" mat-icon-button type="button">
      <mat-icon *ngIf="!collapsed" [icIcon]="icRadioButtonChecked" size="14px"></mat-icon>
      <mat-icon *ngIf="collapsed" [icIcon]="icRadioButtonUnchecked" size="14px"></mat-icon>
    </button>
  </div>

  <vex-scrollbar class="flex-auto">
    <div class="sidenav-items">
      <vex-sidenav-item *ngFor="let item of items; trackBy: trackByRoute" [item]="item" [level]="0"></vex-sidenav-item>
    </div>
  </vex-scrollbar>
</div>