import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatPopupComponent } from './chat-popup/chat-popup.component';
import { ChatPanelToggleComponent } from './chat-panel-toggle.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@visurel/iconify-angular';
import { MatRadioModule } from '@angular/material/radio';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRippleModule } from '@angular/material/core';
import { ScrollbarModule } from '@vex/components/scrollbar/scrollbar.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatBadgeModule } from '@angular/material/badge';
import { DateFormatModule } from '@vex/pipes/date-format/date-format.module';
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    IconModule,
    MatRadioModule,
    FlexLayoutModule,
    MatSlideToggleModule,
    MatRippleModule,
    ScrollbarModule,
    MatDividerModule,
    MatBadgeModule,
    DateFormatModule,
    FormsModule,
    MatSidenavModule,
    ScrollingModule
  ],
  entryComponents: [ChatPopupComponent],
  declarations: [ChatPopupComponent, ChatPanelToggleComponent],
  exports: [ChatPanelToggleComponent]
})
export class ChatPanelModule {
}
