<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
  <div>{{title}}</div>
  <button type="button" class="text-secondary" mat-dialog-close mat-icon-button>
    <mat-icon [icIcon]="icClose"></mat-icon>
  </button>
</div>

<mat-dialog-content>
  <p [innerHTML]="message"></p>

  <blockquote [ngClass]="notesBgColor" *ngIf="note && note.length > 0 ">
    <p>
      Note : {{note}}
    </p>
  </blockquote>
</mat-dialog-content>


<mat-dialog-actions align="end">
  <button mat-button (click)="onDismiss()">NO</button>
  <button matRipple color="primary" *ngIf="deleteAllMessage && deleteAllMessage.length > 0"
    class="px-gutter py-2 flex flex-row cursor-pointer select-none items-center text-pink ltr:rounded-bl rtl:rounded-br relative hover:bg-pink-light "
    (click)="onConfirm('all')">{{deleteAllMessage}}</button>
  <button matRipple color="primary"
    class="px-gutter py-2 flex flex-row cursor-pointer select-none items-center text-pink ltr:rounded-bl rtl:rounded-br relative hover:bg-pink-light "
    (click)="onConfirm(true)">YES</button>

</mat-dialog-actions>