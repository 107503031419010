<div fxLayout="row" class="p-4 bg-app-bar text-warn">
  <h4><b>Session Timeout</b></h4>
</div>
<div fxLayout="column" class="text-center pt-4 pb-4">
  <h4 class="pb-2">You will be logged out in</h4>	
  <p style="display: inline;font-size: 30px;font-style: bold"><span class="text-warn">{{remaining_time}}</span> Sec(s)</p>			
  <h4 class="pt-2">Do you want to stay signed in?</h4>
</div>
<div fxLayout="row" class="p-4" fxLayoutAlign="space-between center">
  <button mat-button class="px-gutter py-2 flex flex-row cursor-pointer select-none items-center text-warn ltr:rounded-bl rtl:rounded-br relative hover:bg-warn-light" (click)="onDismiss()">No, sign me out</button>
  <button mat-button class="px-gutter py-2 flex flex-row cursor-pointer select-none items-center text-primary ltr:rounded-bl rtl:rounded-br relative hover:bg-primary-light"
  (click)="onConfirm()">Yes, keep me signed in</button>
</div>