<form #g="ngForm" [formGroup]="fa2Form">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="headline m-0" fxFlex="auto">Enable 2FA</h2>

    <button class="text-secondary" (click)="helperService.confirmationScreen(dialogRef)" mat-icon-button type="button">
      <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
  </div>

  <mat-divider class="-mx-6 text-border"></mat-divider>

  <mat-dialog-content class="mt-3" fxLayout="column">
      <div fxFlex="auto" fxLayout="column">
        <div fxLayoutGap="20px">
          <mat-form-field fxFlex>
            <mat-label>Phone # <span class="validation-red-color">*</span></mat-label>
            <input type="text" formControlName="Phone" matInput (blur)="onPhone($event.target.value)">
            <!-- <mat-icon *ngIf="phoneSpinner" matPrefix>
              <mat-spinner color="accent" diameter="20"></mat-spinner>
            </mat-icon> -->
            <mat-icon [icIcon]="icPhone" class="mr-3" matPrefix></mat-icon>
            <mat-error class="validation-red-color" *ngIf="fa2Form.controls.Phone.hasError('required')">
              Oops! We need your phone number to create your account
            </mat-error>
            <mat-error class="validation-red-color" *ngIf="fa2Form.controls.Phone.hasError('duplicate')">
              Oops! This phone number is already registered with an account.
            </mat-error>
          </mat-form-field>
            <button mat-raised-button type="submit" (click)="sendCode()" [disabled]="disabledSendButton || !fa2Form.controls.Phone.valid" color="primary">send Code</button>
        </div>
        <div>
          <mat-form-field fxFlex>
            <mat-label>SMS Code <span class="validation-red-color">*</span></mat-label>
            <input type="text" formControlName="SMS" matInput>
            <mat-icon [icIcon]="icPerson" class="mr-3" matPrefix></mat-icon>
            <mat-error class="validation-red-color" *ngIf="fa2Form.controls.SMS.hasError('required')">
              Oops! We need SMS Code to enable 2FA
            </mat-error>
          </mat-form-field>
        </div>
        <div align="end">
          <p *ngIf="disabledSendButton == true" class="mt-2">{{timeLeft}} Seconds Left....</p> 
        </div>
      </div>
      
      <div *ngIf="error && !disabledButton">
        <mat-error>{{error.message}}</mat-error>
      </div>
  </mat-dialog-content>

  
<mat-divider class="-mx-6 text-border"></mat-divider>

  <mat-dialog-actions align="end">
    <button mat-button (click)="helperService.confirmationScreen(dialogRef)" type="button">CANCEL</button>
    <button color="primary" mat-raised-button type="submit" [disabled]="disabledButton || !fa2Form.valid" (click)="confirm()">
      Confirm
      <mat-icon *ngIf="disabledButton">
        <mat-spinner color="accent" diameter="20"></mat-spinner>
      </mat-icon>
    </button>
  </mat-dialog-actions>
</form>