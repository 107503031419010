<div class="dropdown" *ngIf="!isLoggedOut">
  <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="dropdown-heading-icon" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon *ngIf="!profilePicture || profilePicture.length == 0" [icIcon]="icPerson">
        </mat-icon>
        <img @scaleIn class="avatar h-15 w-18 flex-none align-start hidden sm:block border-3 border-white"
          [src]="profilePicture" *ngIf="profilePicture" onError="this.src='assets/default/download.png'">
      </div>
      <div class="dropdown-heading">{{name}}</div>
    </div>

    <!-- <button [matMenuTriggerFor]="settingsMenu" mat-icon-button matTooltip="Settings" matTooltipPosition="before"
      type="button">
      <mat-icon [icIcon]="icSettings" class="notifications-header-icon"></mat-icon>
    </button> -->
  </div>

  <div class="dropdown-content">
    <a (click)="close()" *ngFor="let item of items; trackBy: trackById" [routerLink]="item.route" class="notification"
      fxLayout="row" fxLayoutAlign="start center" matRipple>
      <mat-icon [icIcon]="item.icon" [ngClass]="item.colorClass" class="notification-icon" fxFlex="none"></mat-icon>
      <div fxFlex="auto">
        <div class="notification-label">{{ item.label }}</div>
        <div class="notification-description">{{ item.description }}</div>
      </div>
      <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
    </a>
    <a (click)="close(); changePassword()" class="notification" fxLayout="row" fxLayoutAlign="start center" matRipple>
      <mat-icon [icIcon]="icLock" class="notification-icon bg-primary-light text-primary" fxFlex="none"></mat-icon>
      <div fxFlex="auto">
        <div class="notification-label">Change Password</div>
        <div class="notification-description">Change Password</div>
      </div>
      <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
    </a>
  </div>

  <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="end">
    <a (click)="logout()" [routerLink]="['/login']" color="primary" mat-button>LOGOUT</a>
  </div>
</div>

<mat-menu #statusMenu="matMenu" xPosition="before" yPosition="below">
</mat-menu>