import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'vex-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  items = [];

  constructor(private navigationService: NavigationService) {
    this.navigationService.getItem().subscribe(d => {
      this.items = d;
    });
  }

  ngOnInit() {
    if ((!this.items || this.items.length === 0) && this.navigationService.Items && this.navigationService.Items.length > 0) {
      this.items = this.navigationService.Items;
    }
  }
}
