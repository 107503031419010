import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import icSettings from '@iconify/icons-ic/message';
import { MatDialog } from '@angular/material/dialog';
import { ChatPopupComponent } from './chat-popup/chat-popup.component';
import { LayoutService } from '@vex/services/layout.service';

declare var $;

@Component({
  selector: 'vex-chat-panel-toggle',
  templateUrl: './chat-panel-toggle.component.html',
  styleUrls: ['./chat-panel-toggle.component.scss']
})
export class ChatPanelToggleComponent implements OnInit {

  icSettings = icSettings;
  part: any = [];
  offset: number = 0;
  forwards: boolean = true;
  skip_count: number = 0;
  skip_delay: number = 15;
  speed: number = 70;
  word = 'Worker Updates';
  prevNowPlaying: any = null;
  isFisrtTime: boolean = true;
  isHover: boolean = false;
  notificationCount: number = 0;

  constructor(
    private dialog: MatDialog,
    public layoutService: LayoutService,
    private cdref: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.wordflick();
  }

  getWorkerCount() {
    return this.layoutService.workerMessages.filter(message => !message.IsRead).length;
  }

  wordflick(isHover: boolean = false) {
    let scope = this;
    if(!this.isFisrtTime) {
      this.isFisrtTime = true;
    }
    if(this.isFisrtTime) {
      this.prevNowPlaying = setInterval(function () {
        if(scope.isFisrtTime) { 
          if (scope.forwards) {
            if (scope.offset >= scope.word.length) {
              ++scope.skip_count;
              if (scope.skip_count == scope.skip_delay) {
                scope.forwards = false;
                scope.skip_count = 0;
              }
            }
          }
          else {
            if (scope.offset == 0) {
              scope.forwards = true;
              scope.offset = 0;
              clearInterval(scope.prevNowPlaying);
              scope.isFisrtTime = false;
            }
          }
          scope.part = scope.word.substr(0, scope.offset);
          if (scope.skip_count == 0) {
            if (scope.forwards) {
              scope.offset++;
            }
            else {
              if(!scope.isHover) {
                scope.offset--;
              }
            }
          }
          $('#word').text(scope.part);
        }
      }, scope.speed);
    }
    
  }

  openChat() {
    this.dialog.open(ChatPopupComponent, {
      panelClass: 'custom-dialog-container',
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '80%',
      disableClose: true,
    })
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
}
