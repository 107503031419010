import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { trackByRoute } from '../../utils/track-by';
import { NavigationService } from '../../services/navigation.service';
import icRadioButtonChecked from '@iconify/icons-ic/twotone-radio-button-checked';
import icRadioButtonUnchecked from '@iconify/icons-ic/twotone-radio-button-unchecked';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../services/config.service';
import { map } from 'rxjs/operators';
import { Style, StyleService } from '@vex/services/style.service';

@Component({
  selector: 'vex-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Input() collapsed: boolean;
  onboard: boolean = false;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(map(config => config.sidenav.title));
  imageUrl$ = this.configService.config$.pipe(map(config => config.sidenav.imageUrl));
  showCollapsePin$ = this.configService.config$.pipe(map(config => config.sidenav.showCollapsePin));
  style$ = this.styleService.style$.pipe(map(config => config));
  styles$ = Style;

  items = [];
  trackByRoute = trackByRoute;
  icRadioButtonChecked = icRadioButtonChecked;
  icRadioButtonUnchecked = icRadioButtonUnchecked;

  constructor(private navigationService: NavigationService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private styleService: StyleService,
    private router: Router) {
    this.navigationService.getItem().subscribe(d => {
      this.items = d;
    });
    this.configService.config$.subscribe(config => {
      this.onboard = config.sidenav.onboard;
    });
  }

  ngOnInit() {
    if ((!this.items || this.items.length === 0) && this.navigationService.Items && this.navigationService.Items.length > 0) {
      this.items = this.navigationService.Items;
    }
  }

  onMouseEnter() {
    if(!this.onboard)
     this.layoutService.collapseOpenSidenav();
  }

  onMouseLeave() {
    if(!this.onboard)
      this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    this.collapsed ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
  }

  onClickImg(){
    this.router.navigate(['/onboard']);
  }
}
