import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { stagger40ms } from '@vex/animations/stagger.animation';
import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from '@vex/animations/scale-fade-in.animation';
import { scaleIn400ms } from '@vex/animations/scale-in.animation';
import { fadeInRight400ms } from '@vex/animations/fade-in-right.animation';
import icAccessTime from '@iconify/icons-ic/twotone-access-time';
import icAdd from '@iconify/icons-ic/twotone-add';
import icWhatshot from '@iconify/icons-ic/twotone-whatshot';
import icWork from '@iconify/icons-ic/twotone-work';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icCheck from '@iconify/icons-ic/twotone-check';
import icLock from '@iconify/icons-ic/twotone-lock';
import icMail from '@iconify/icons-ic/twotone-mail';
import icPhoneMsg from '@iconify/icons-ic/perm-phone-msg';
import { Icons } from '@vex/icons/icons';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '@app/user-setting/change-password/change-password.component';
import { EnableFAComponent } from '@app/user-setting/enable-fa/enable-fa.component';
import {
    ConfirmDialogTitle,
    ConfirmDialogMessage,
    ConfirmDialogMaxWidth
} from '@vex/constants/settings';
import {
    ConfirmDialogModel,
    ConfirmDialogComponent
} from '@vex/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@vex/http-service/user.service';
import { InputControl } from '@vex/utils/input-control';
import { FieldMandatory, Createsuccess, Updatesuccess } from '@vex/constants/settings';
import { LayoutService } from '@vex/services/layout.service';
import { I } from '@angular/cdk/keycodes';
import { SessionTimeComponent } from '@app/user-setting/session-time/session-time.component';
@Component({
    selector: 'user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
    animations: [fadeInUp400ms, fadeInRight400ms, scaleIn400ms, stagger40ms]
})
export class UserProfileComponent extends Icons implements OnInit {
    dialogData = new ConfirmDialogModel(ConfirmDialogTitle, ConfirmDialogMessage);
    confirmModalData = {
        maxWidth: ConfirmDialogMaxWidth,
        data: this.dialogData
    };

    icWork = icWork;
    icPhone = icPhone;
    icPersonAdd = icPersonAdd;
    icCheck = icCheck;
    icMail = icMail;
    icAccessTime = icAccessTime;
    icAdd = icAdd;
    icWhatshot = icWhatshot;
    icLock = icLock;
    icPhoneMsg = icPhoneMsg;
    src: any;
    userData: any = {};
    oldPicture: any = '';
    phoneNumber: any;
    confirmEmail: boolean = false;
    confirmedPhone: boolean = false;
    twoFactorEnabled: boolean = false;
    email: any = '';
    emailmessage: boolean = false;
    showInput: boolean = false;
    form: FormGroup;
    disabledSaveButton: boolean = false;
    aboutTab: boolean = true;
    recentActivityTab: boolean = false;
    //addressTable = new loadData();

    @ViewChild('imgReader', { static: true }) imgReader: any;

    constructor(
        private dialog: MatDialog,
        private userService: UserService,
        private toastrService: ToastrService,
        private fb: FormBuilder,
        private inputControl: InputControl,
        private layoutService: LayoutService
    ) {
        super();
    }

    ngOnInit(): void {
        this.getUserInfo();
        this.updateProfile();
    }

    getUserInfo() {
        this.userService.getUserInfo().subscribe((res) => {
            this.userData = res;
            const currentUser = JSON.parse(localStorage.getItem('Info'));
            currentUser['Name'] = this.userData.FirstName + ' ' + this.userData.LastName;
            //currentUser['Name'] = this.userData.LastName;
            localStorage.setItem('Info', JSON.stringify(currentUser));
            this.layoutService.showHeader = false;
            this.layoutService.userName = currentUser['Name'];
            this.layoutService.profilePicture = this.userData.PicturePath;
            let scope = this;
            setTimeout(() => {
                scope.layoutService.showHeader = true;
            }, 1);

            this.oldPicture = this.userData.PicturePath;
            this.phoneNumber = this.userData.PhoneNumber;
            this.confirmEmail = this.userData.EmailConfirmed;
            this.twoFactorEnabled = this.userData.TwoFactorEnabled;
            this.confirmedPhone = this.userData.PhoneNumberConfirmed;
            this.email = this.userData.Email;
        });
    }

    inputState(formControl, form, attr) {
        return this.inputControl.isErrorState(formControl.controls[attr], form);
    }

    uploadFile(event) {
        const files = event.srcElement.files;
        if (this.isValidPNGFile(files[0])) {
            if (event.target.files.length > 0) {
                const file = event.target.files[0];
                this.uplaodPictue(file);
            }
        } else {
            this.toastrService.info('Please upload valid .png file.');
            this.fileReset();
        }
    }

    uplaodPictue(file) {
        this.userService.updateloadImage(this.userData.Id, file).subscribe(() => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (_event) => {
                this.userData.PicturePath = reader.result;
            };
        });
    }

    fileReset() {
        this.userData.PicturePath = this.oldPicture;
    }

    changeTab(i) {
        this.aboutTab = true;
        if (i.index == 1) {
            this.aboutTab = false;
            this.recentActivityTab = true;
        }
    }

    isValidPNGFile(file: any) {
        return file.name.endsWith('.png');
    }

    changePassword() {
        this.dialog
            .open(ChangePasswordComponent, {
                disableClose: true,
                width: '40%'
            })
            .afterClosed()
            .subscribe((r) => {
                if (r != undefined && r != '') {
                    this.toastrService.info('Password has been changed');
                }
            });
    }

    changeSessionTime() {
        this.dialog
        .open(SessionTimeComponent, {
            width: '40%',
            disableClose: true,
            data: this.userData
        })
        .afterClosed()
        .subscribe((res) => {
            if (res != undefined && res != '') {
                this.userData.SessionTime = res.SessionTime;
                this.toastrService.info('Session Time has been changed');
            }
        });
    }

    updateProfile(status: boolean = false) {
        this.showInput = status;

        this.form = this.fb.group({
            Id: [this.userData.Id],
            FirstName: [this.userData.FirstName],
            LastName: [this.userData.LastName || ''],
            Address: [this.userData.Address],
            City: [this.userData.City],
            State: [this.userData.State],
            Zip: [this.userData.Zip],
            PhoneNumber: [this.userData.PhoneNumber],
            Email: [{ value: this.userData.Email, disabled: true }, [Validators.required]],
            UserName: [{ value: this.userData.UserName, disabled: true }, [Validators.required]]
        });
    }

    saveUserProfile() {
        let updateUser = this.form.value;
        let newData = {
            ...this.userData,
            ...updateUser
        }
        this.disabledSaveButton = true;
        this.userService.updateUser(newData).subscribe(
            (response) => {
                this.getUserInfo();
                this.toastrService.success(Updatesuccess);
                this.showInput = false;
                this.disabledSaveButton = false;
                // this.userData = response;
            },
            (error: any) => {
                this.disabledSaveButton = false;
                this.toastrService.error('Invalid');
            }
        );
    }

    close() {
        this.showInput = false;
    }

    enable2FA() {
        this.dialog
            .open(EnableFAComponent, {
                width: '40%',
                disableClose: true,
                data: this.phoneNumber
            })
            .afterClosed()
            .subscribe((r) => {
                if (r != undefined) {
                    this.userData.PhoneNumberConfirmed = true;
                }
            });
    }

    sendEmail() {
        this.emailmessage = false;
        this.userService.sendConfirmEmail(this.email).subscribe((res) => {
            this.emailmessage = true;
        });
    }
}
